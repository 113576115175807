import { AdminData, User } from "../interfaces"
import { Settings } from "./settings"

const settings = {
	host: Settings.apiHost,
	prefix: "/v1",
}

export interface ApiError {
	status: number
	errors: string[]
}

export const isApiError = (v: any): v is ApiError => {
	return (v as ApiError).errors !== undefined
}

const doRequest = async (path: string, options?: RequestInit) => {
	options = {
		...options,
		headers: {
			...options?.headers,
			"X-Requested-With": "XMLHttpRequest",
		},
		credentials: "include",
	}
	const resp = await fetch(`${settings.host}${settings.prefix}${path}`, options).then((r) => r.json())
	if (isApiError(resp)) {
		if (resp.status === 401) {
			throw new Error(`unauthorized`)
		}
	}
	return resp
}

export default {
	me: (): Promise<User> => {
		return doRequest(`/users/me`)
	},

	getAdminData: (): Promise<AdminData> => {
		return doRequest(`/admin`)
	},

	sendInvite: ({ id }: { id: string }): Promise<{} | ApiError> => {
		return doRequest(`/admin/invites/send`, {
			method: "POST",
			body: JSON.stringify({ id }),
		})
	},

	ignoreInvite: ({ id }: { id: string }): Promise<{} | ApiError> => {
		return doRequest(`/admin/invites/ignore`, { method: "POST", body: JSON.stringify({ id }) })
	},

	unignoreInvite: ({ id }: { id: string }): Promise<{} | ApiError> => {
		return doRequest(`/admin/invites/unignore`, { method: "POST", body: JSON.stringify({ id }) })
	},
}
