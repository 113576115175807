import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"

export const Button = styled(({ className, children, whileHoverScale, whileTapScale, ...rest }: any) => {
  return (
    <motion.button
      className={className}
      whileHover={{
        scale: whileHoverScale || 1.05,
      }}
      whileTap={{
        scale: whileTapScale || 0.95,
      }}
      {...rest}
    >
      {children}
    </motion.button>
  )
})``
