import React, { useContext } from "react"
import { Redirect, Route } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"

const AuthorizedRoute = ({ roles, component, ...rest }: any) => {
  const { auth } = useContext(AuthContext)
  if (!auth.done) {
    return null
  }
  if (!auth.isAuthenticated) {
    return (
      <Route {...rest} render={({ location }) => <Redirect to={{ pathname: "/404", state: { from: location } }} />} />
    )
  }
  if (roles && !roles.includes(auth.user.role)) {
    return (
      <Route {...rest} render={({ location }) => <Redirect to={{ pathname: "/404", state: { from: location } }} />} />
    )
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return component({ ...props, auth })
      }}
    />
  )
}

export default AuthorizedRoute
