import React, { createContext, useEffect, useState } from "react"
import { User } from "../interfaces"
import api, { isApiError } from "../utils/api"

export interface Auth {
  isAuthenticated: boolean
  user: User
  done: boolean
}

export interface AuthContextType {
  auth: Auth
}

const defaultAuth = {
  isAuthenticated: false,
  user: {} as User,
  done: false,
}

export const AuthContext = createContext<AuthContextType>({
  auth: defaultAuth,
})

const AuthContextProvider = (props: any) => {
  const [auth, setAuth] = useState(defaultAuth)
  useEffect(() => {
    api
      .me()
      .then((r) => {
        if (isApiError(r)) {
          setAuth({ ...defaultAuth, done: true })
        }
        if (r.id) {
          setAuth({
            isAuthenticated: true,
            user: r,
            done: true,
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])
  return <AuthContext.Provider value={{ auth }}>{props.children}</AuthContext.Provider>
}

export default AuthContextProvider
