import { BanIcon, PaperAirplaneIcon } from "@heroicons/react/solid"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import { Button } from "../components/Button"
import { AuthContext } from "../contexts/AuthContext"
import { AdminData } from "../interfaces"
import api, { isApiError } from "../utils/api"

interface Props {
  className?: string
}

const AdminPageComponent = (props: Props) => {
  const { auth } = useContext(AuthContext)

  const [adminData, setAdminData] = useState<AdminData>()
  const [sending, setSending] = useState("")
  const [showIgnored, setShowIgnored] = useState(false)

  useEffect(() => {
    if (!auth.done || auth.user.role !== "admin") {
      return
    }
    api.getAdminData().then(setAdminData)
  }, [auth])

  if (!auth.done) {
    return null
  }
  if (auth.user.role !== "admin") {
    return <Redirect to="/404" />
  }

  return (
    <div className={props.className}>
      <h1>Admin</h1>
      {adminData ? (
        <>
          <div className="block">
            <h2>Users</h2>
            <table>
              <tbody>
                <tr>
                  <td>Active</td>
                  <td>{adminData.users.count}</td>
                </tr>
                <tr>
                  <td>In waitlist</td>
                  <td>{adminData.users.waitlist.length}</td>
                </tr>
                <tr>
                  <td>Invited percentage</td>
                  <td>
                    {adminData.users.count === 0
                      ? 0
                      : Math.round(
                          (100 * adminData.users.count) / (adminData.users.waitlist.length + adminData.users.count)
                        )}
                    %
                  </td>
                </tr>
                <tr>
                  <td>Activated</td>
                  <td>
                    {adminData.users.activated} / {adminData.users.count}
                    <br />
                    {adminData.users.activated === 0
                      ? 0
                      : Math.round(100 * (adminData.users.activated / adminData.users.count))}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="block">
            <h2>Notes</h2>
            <table>
              <tbody>
                <tr>
                  <td>Total</td>
                  <td>{adminData.notes.count}</td>
                </tr>
                <tr>
                  <td>Encrypted</td>
                  <td>{adminData.notes.encrypted}</td>
                </tr>
                <tr>
                  <td>Shared</td>
                  <td>{adminData.notes.shared}</td>
                </tr>
                <tr>
                  <td>Standalone</td>
                  <td>
                    {adminData.notes.standalone} <br />
                    {adminData.notes.standalone === 0
                      ? 0
                      : Math.round(100 * (adminData.notes.standalone / adminData.notes.count))}
                    %
                  </td>
                </tr>
                <tr>
                  <td>Folders</td>
                  <td>{adminData.notes.folders}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="block">
            <h2>Tasks</h2>
            <table>
              <tbody>
                <tr>
                  <td>Total</td>
                  <td>{adminData.tasks.count}</td>
                </tr>
                <tr>
                  <td>Checked</td>
                  <td>{adminData.tasks.checked}</td>
                </tr>
                <tr>
                  <td>Checked percentage</td>
                  <td>
                    {adminData.tasks.count === 0
                      ? 0
                      : Math.round((100 * adminData.tasks.checked) / adminData.tasks.count)}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="block">
            <div className="heading">
              <h2>Waitlist</h2>
              <Button className="secondary" onClick={() => setShowIgnored(!showIgnored)}>
                {showIgnored ? "Hide" : "Show"} ignored ({adminData.users.waitlist.filter((i) => !!i.ignored).length})
              </Button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th className="waitlist-created-at">Created at</th>
                  <th className="waitlist-link">Link</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {adminData.users.waitlist
                  .filter((inv) => showIgnored || !inv.ignored)
                  .sort((a, b) => {
                    return -(a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
                  })
                  .map((inv, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {inv.ignored && <span className="danger">(ignored)</span>} {inv.email}
                        </td>
                        <td className="mono waitlist-created-at">{moment(inv.createdAt).format("YYYY-MM-DD")}</td>
                        <td className="waitlist-link">
                          <a
                            className="mono"
                            href={`${window.location.protocol}//${window.location.host}/signup?inviteId=${inv.id}`}
                          >
                            {inv.id}
                          </a>
                        </td>
                        <td>
                          {inv.sentAt ? (
                            <div>Invite sent</div>
                          ) : (
                            <>
                              <Button
                                disabled={sending === inv.id}
                                onClick={() => {
                                  setSending(inv.id)
                                  api.sendInvite({ id: inv.id }).then((r) => {
                                    setSending("")
                                    if (isApiError(r)) {
                                      console.error(r)
                                      return
                                    }
                                    setAdminData({
                                      ...adminData,
                                      users: {
                                        ...adminData.users,
                                        waitlist: adminData.users.waitlist.map((wli) => {
                                          return {
                                            ...wli,
                                            sentAt: wli.id === inv.id ? new Date().toUTCString() : wli.sentAt,
                                          }
                                        }),
                                      },
                                    })
                                  })
                                }}
                              >
                                <PaperAirplaneIcon /> Send invite
                              </Button>
                              <Button
                                className={inv.ignored ? "info" : "danger"}
                                onClick={() => {
                                  const fn = inv.ignored ? api.unignoreInvite : api.ignoreInvite
                                  fn({ id: inv.id }).then((r) => {
                                    if (isApiError(r)) {
                                      window.alert(r.errors.join(","))
                                      return
                                    }
                                    setAdminData({
                                      ...adminData,
                                      users: {
                                        ...adminData.users,
                                        waitlist: adminData.users.waitlist.map((wli) => {
                                          if (wli.id !== inv.id) {
                                            return wli
                                          }
                                          return {
                                            ...wli,
                                            ignored: inv.ignored ? false : true,
                                          }
                                        }),
                                      },
                                    })
                                  })
                                }}
                              >
                                {inv.ignored ? (
                                  <>
                                    <BanIcon /> Un-ignore
                                  </>
                                ) : (
                                  <BanIcon />
                                )}
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
}

const AdminPage = styled(AdminPageComponent)`
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  .block {
    border: 1px solid var(--nc-bg-3);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px 20px;
    ${down("md")} {
      padding: 0;
      border: none;
    }
  }

  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h2 {
      flex: 1;
    }
  }

  ${down("md")} {
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.4em;
    }
    h3 {
      font-size: 1.3em;
    }
    font-size: 14px;
    button {
      font-size: 12px;
    }
    padding: 10px;
  }
  ${down("md", "portrait")} {
    .waitlist-created-at {
      font-size: 10px;
    }
    .waitlist-link {
      display: none;
    }
  }

  .danger {
    background: #e13;
  }

  .secondary {
    background: #999;
  }

  .info {
    background: #999;
  }
`

export default AdminPage
