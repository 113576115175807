import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import AuthorizedRoute from "./components/AuthorizedRoute"
import AuthContextProvider from "./contexts/AuthContext"
import NotFoundPage from "./pages/404"
import AdminPage from "./pages/admin"

const App = () => {
  return (
    <AuthContextProvider>
      <Router>
        <Switch>
          <AuthorizedRoute
            path="/"
            roles={["admin"]}
            exact={true}
            component={(props: any) => <AdminPage {...props} />}
          />
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </AuthContextProvider>
  )
}

export default App
